html {
   font-family: 'Open Sans', sans-serif;
}

body {
   font-family: 'Open Sans', sans-serif;
}

button,
a {
   font-family: 'Open Sans', sans-serif;
}

main {
   display: flex;
   flex-direction: column;
   margin: 1rem 0 12rem 0;
}

.container-xxl {
   max-width: 1340px;
   width: 100%;
   padding-left: 15px;
   padding-right: 15px;
   margin-left: auto;
   margin-right: auto;
}

.response-error {
   color: #ff0000;
}

.btn-icon > .svg-inline--fa {
   margin-right: 1rem;
}

.btn-icon-left > .svg-inline--fa {
   margin-right: 1rem;
}

.btn-icon-right > .svg-inline--fa {
   margin-left: 1rem;
}

.invalid-feedback {
   display: block;
}
