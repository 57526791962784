.react-calendar {
   width: 100%;
}

.react-calendar__tile,
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
   background: none;
   border: none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
   display: none;
}

.react-calendar__month-view__weekdays__weekday {
   text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
   text-decoration: none;
   font-weight: bold;
}

.react-calendar__navigation__label,
.react-calendar__year-view__months__month {
   font-weight: bold;
}

.react-calendar__tile--now {
   background: rgba(33, 136, 56, 0.6);
   color: #fff;
}

.react-calendar__month-view__days__day--neighboringMonth {
   opacity: 0.5;
}

.react-calendar__tile--range:not(.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd) {
   background: rgba(229, 0, 25, 0.6);
   color: #fff;
}

.react-calendar__tile:hover {
   background: #e50019;
   color: #fff;
   opacity: 1;
}

.react-calendar__tile--active,
.react-calendar__year-view__months__month.react-calendar__tile--hasActive,
.react-calendar__tile--rangeStart,
.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
   background: #e50019;
   color: #fff;
   opacity: 1;
}

.react-calendar__tile,
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
   border-radius: 0.25rem;
}

/*
* xl
*/

.react-calendar.xl .react-calendar__navigation__arrow {
   padding: 1rem 1.5rem;
}

.react-calendar.xl .react-calendar__tile,
.react-calendar.xl .react-calendar__navigation__label {
   padding: 1rem 0;
}

.react-calendar.xl .react-calendar__month-view__weekdays__weekday {
   padding: 1rem 0;
}

.react-calendar.xl .react-calendar__navigation__arrow {
   padding: 1rem 1.5rem;
}

/*
* sm
*/

.react-calendar.sm .react-calendar__navigation__arrow {
   padding: 1rem 1.5rem;
}

.react-calendar.sm .react-calendar__navigation__label,
.react-calendar.sm .react-calendar__navigation__arrow {
   padding: 0.5rem 1rem;
}

.react-calendar.sm .react-calendar__tile {
   padding: 0.5rem 0;
}

.react-calendar.sm .react-calendar__month-view__weekdays__weekday {
   padding: 1rem 0;
}
